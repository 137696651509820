import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'folder/Inbox',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home-student',
    loadChildren: () => import('./home-student/home-student.module').then( m => m.HomeStudentPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./student/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'test-results',
    loadChildren: () => import('./student/test-results/test-results.module').then( m => m.TestResultsPageModule)
  },
  {
    path: 'materials',
    loadChildren: () => import('./student/materials/materials.module').then( m => m.MaterialsPageModule)
  },
  {
    path: 'online-exam',
    loadChildren: () => import('./student/online-exam/online-exam.module').then( m => m.OnlineExamPageModule)
  },
  {
    path: 'i-card',
    loadChildren: () => import('./student/i-card/i-card.module').then( m => m.ICardPageModule)
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
