import { Component } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { ApiService } from './api.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  currentPageTitle = 'Dashboard';

  appPages = [
    {
      title: 'Dashboard',
      url: '',
    },
  ];

 // public studentProfile:any;
  constructor(
    public api:ApiService,
    public nav:NavController,
    public alertController:AlertController
  ) {
    if(localStorage.getItem('studentProfile')){
      // this.api.getSExamDetail().subscribe((result) => {
      //   console.log(result);
      //   this.api.studentData=result;
     //  console.log('exam detailsss',this.studentData)
      // },err=>{
      //   console.log("Server Error");
      // });
     // get student data from local storage
     this.nav.navigateRoot('home-student');
     
  //  this.studentProfile=JSON.parse(localStorage.getItem('student-profile'));
   // console.log(this.studentProfile)
    }else{
      this.nav.navigateRoot('login');
    }
     // this.nav.navigateRoot('login');
  }
  dashboard(){
    this.nav.navigateRoot('home-student');
  }
  profile(){
    this.nav.navigateRoot('profile');
  }
  testResults(){
    this.nav.navigateRoot('test-results');
  }
  materials(){
    this.nav.navigateRoot('materials');
  }
  exam(){
   let data=JSON.parse(localStorage.getItem('studentProfile'));
   console.log(data);
   if(data.is_exam_attended==false){
    this.nav.navigateRoot('online-exam');
    
  }else{
    console.log(data.is_exam_attended)
    alert('You have already submitted this exam');
  }



//     this.api.getSExamDetail().subscribe((result) => {
//     this.api.studentData=result;
  
//  //  console.log('exam detailsss',this.studentData)
//   },err=>{
//     console.log("Server Error");
//   });
//     console.log('student',this.api.studentData)
    // if(!this.api.studentData.examDetail){
    //   this.nav.navigateRoot('online-exam');
      
    // }else{
    //   console.log(this.api.studentData)
    //   alert('You have already submitted this exam');
    // }
  }
  iCard(){
    this.nav.navigateRoot('i-card');
  }
  async logout(){
    
    const alert = await this.alertController.create({
      header: 'Are you sure you want to logout?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {  }
        },
        {
          text: 'Yes',
          role: 'confirm',
          handler: () => {
            localStorage.clear();
            this.nav.navigateRoot('login');
          }
        }
      ]
    });

    await alert.present();
    
  }
}
