import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public showMenu:any=true; 
  public studentData:any;
  public baseUrl: string = "https://us-central1-aicasindia-in.cloudfunctions.net/app/api/";
  constructor( private afs: AngularFirestore,
    public http:HttpClient
    ) { }
  // getStudentAllData(data){
  //   return this.afs.collection('all-students').doc(data.id+data.password).valueChanges();
  // }
  // getMaterials(){
  //   let fid = JSON.parse(localStorage.getItem('student-profile')).franchises_details.registerNo ;
  //   return this.afs.collection('franchises').doc(fid).collection('materials').valueChanges();
  // }
  // getStudentProfile(){
  //   let sid =localStorage.getItem('studentId') ;
  //   return this.afs.collection('all-students').doc(sid).valueChanges();
  // }
  //!exam
  getQuestions(language){
   // let fid = JSON.parse(localStorage.getItem('student-profile')).franchises_details.registerNo ;
   // return this.afs.collection('franchises').doc(femail).collection('questions').valueChanges();
    
    if(language=='gujarati'){
      return this.afs.collection('questions').doc('gujarati').collection('gujarati-questions').valueChanges();
    }else{
      return this.afs.collection('questions').doc('english').collection('english-questions').valueChanges();
    }
   }

  //  saveStudentExam(data,count){
    
  //   let fid = JSON.parse(localStorage.getItem('student-profile')).franchises_details.registerNo ;
  //   let student = JSON.parse(localStorage.getItem('student-profile'));
  //   let temp={
  //     answers:data,
  //     practical_mark:count,
  //     isApprove:false
  //   }
  //   student.examDetail=temp;
    
  //   console.log(student)
  //   return this.afs.collection('franchises').doc(fid).collection('students').doc(student.id).set(student);
  //  }
   getStudentExamDetail(data){
    //let femail = JSON.parse(localStorage.getItem('student-profile')).franchises_details.email ;
    let fid=data.franchises_details.registerNo ;
    let sid =localStorage.getItem('sId');
    console.log(sid)
    return this.afs.collection('franchises').doc(fid).collection('students').doc(sid).valueChanges();
   }
  //  getSExamDetail(){
  //   let fid = JSON.parse(localStorage.getItem('student-profile')).franchises_details.registerNo ;
  //  // let fid=data.franchises_details.registerNo ;
  //   let sid =localStorage.getItem('sId');
  //   console.log(sid)
  //   return this.afs.collection('franchises').doc(fid).collection('students').doc(sid).valueChanges();
  //  }
  //!   //////////////////////////////////////////////////////////////////////////////////////////////////////////

//get students for login 

getStudent(data){
  return this.http.post(this.baseUrl+"students/login",data);
}

getMaterials(data){
  return this.http.post(this.baseUrl+"materials/get_materials",data);
}
getQuestion(language){
  return this.http.post(this.baseUrl + "questions/get_question", language);
}
saveExamAnswers(data){
  return this.http.post(this.baseUrl + "answers/add_answers", data);
}
saveExamWithStatusUpdate(data){
  return this.http.post(this.baseUrl+"students/update",data);
}
getOneStudentWithExamDetail(data){
  return this.http.post(this.baseUrl+"students/getStudent_byFranchises_withExamDetail",data);
 
}
  }

